import { Switch, Route } from 'react-router-dom';
import NotFoundPage from 'components/pages/NotFoundPage';
import SitemapStatic from './Sitemaps/SitemapStatic';
import SitemapCatalog from './Sitemaps/SitemapCatalog';
import SitemapItems from './Sitemaps/SitemapItems';
import SitemapNews from './Sitemaps/SitemapNews';
import SitemapSales from './Sitemaps/SitemapSales';

const Sitemap = () => (
  <Switch>
    <Route path="/sitemap-static.xml" exact component={SitemapStatic} />
    <Route path="/sitemap-catalog.xml" exact component={SitemapCatalog} />
    <Route path="/sitemap-items-:page.xml" exact component={SitemapItems} />
    <Route path="/sitemap-news.xml" exact component={SitemapNews} />
    <Route path="/sitemap-sales.xml" exact component={SitemapSales} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Sitemap;
