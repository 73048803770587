import { Query } from 'react-apollo';
import constants from 'constants/constants';
import itemsQuery from 'graphql/queries/sitemapItems.graphql';
import Status from 'components/atoms/Status';
import NotFoundPage from 'components/pages/NotFoundPage';

const SitemapItems = ({ staticContext, match }) => {
  const rawPage = match.params.page;

  if (typeof rawPage !== 'undefined' && (isNaN(+rawPage) || +rawPage <= 0)) {
    return <NotFoundPage />;
  }

  const page = +rawPage || 1;

  return (
    <Query
      context={{
        sendAppToken: true,
      }}
      query={itemsQuery}
      variables={{
        skip: (match.params.page - 1) * 1000,
      }}
    >
      {({ loading, data, error }) => {
        if (loading) {
          return '';
        }

        if (error) {
          return <Status code={500} />;
        }

        const totalPages = Math.ceil(data.itemsConnection.aggregate.count / 1000);

        if (page > totalPages && totalPages !== 0) {
          return <NotFoundPage />;
        }

        if (staticContext) {
          staticContext.disableLayout = true;
          staticContext.contentType = 'text/xml';
          staticContext.unsafeBeforeString = '<?xml version="1.0" encoding="UTF-8"?>\n';
        }

        return (
          <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            {data.items.map((item) => (
              <url key={item.id}>
                <loc>{`${constants.BASE_URL}/catalog/${item.category.translit}/${item.id}`}</loc>
              </url>
            ))}
          </urlset>
        );
      }}
    </Query>
  );
};

export default SitemapItems;
