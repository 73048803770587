import { Query } from 'react-apollo';
import constants from 'constants/constants';
import salesQuery from 'graphql/queries/sitemapSales.graphql';
import Status from 'components/atoms/Status';

const SitemapNews = ({ staticContext }) => (
  <Query
    query={salesQuery}
    context={{
      sendAppToken: true,
    }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return '';
      }

      if (error) {
        return <Status code={500} />;
      }

      if (staticContext) {
        staticContext.disableLayout = true;
        staticContext.contentType = 'text/xml';
        staticContext.unsafeBeforeString = '<?xml version="1.0" encoding="UTF-8"?>\n';
      }

      return (
        <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
          <url>
            <loc>{`${constants.BASE_URL}/sales`}</loc>
          </url>
          {data.salesConnection.edges.map((saleEdge) => (
            <url key={saleEdge.node.id}>
              <loc>{`${constants.BASE_URL}/sales/${saleEdge.node.id}`}</loc>
            </url>
          ))}
        </urlset>
      );
    }}
  </Query>
);

export default SitemapNews;
