import { Query } from 'react-apollo';
import constants from 'constants/constants';
import staticPagesQuery from 'graphql/queries/sitemapStaticPages.graphql';
import representatives from 'components/pages/RepresentativesPage/representatives';
import Status from 'components/atoms/Status';

const SitemapStatic = ({ staticContext }) => {
  return (
    <Query
      query={staticPagesQuery}
      context={{
        sendAppToken: true,
      }}
    >
      {({ loading, data, error }) => {
        if (loading) {
          return '';
        }

        if (error) {
          return <Status code={500} />;
        }

        if (staticContext) {
          staticContext.disableLayout = true;
          staticContext.contentType = 'text/xml';
          staticContext.unsafeBeforeString = '<?xml version="1.0" encoding="UTF-8"?>\n';
        }

        return (
          <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            <url>
              <loc>{`${constants.BASE_URL}/`}</loc>
            </url>
            <url>
              <loc>{`${constants.BASE_URL}/privacy-policy`}</loc>
            </url>
            <url>
              <loc>{`${constants.BASE_URL}/about`}</loc>
            </url>
            <url>
              <loc>{`${constants.BASE_URL}/payment-and-delivery`}</loc>
            </url>
            <url>
              <loc>{`${constants.BASE_URL}/feedbacks`}</loc>
            </url>
            <url>
              <loc>{`${constants.BASE_URL}/contacts`}</loc>
            </url>
            <url>
              <loc>{`${constants.BASE_URL}/contacts/team`}</loc>
            </url>
            <url>
              <loc>{`${constants.BASE_URL}/contacts/predstaviteli-v-regionah`}</loc>
            </url>
            {Array.from(representatives.keys()).map((key) => (
              <url key={key}>
                <loc>{`${constants.BASE_URL}/contacts/predstaviteli-v-regionah/${key}`}</loc>
              </url>
            ))}
            {data.staticPages.map((staticPage) => (
              <url key={staticPage.id}>
                <loc>{`${constants.BASE_URL}${staticPage.path}`}</loc>
              </url>
            ))}
          </urlset>
        );
      }}
    </Query>
  );
};

export default SitemapStatic;
